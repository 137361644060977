import { Injectable } from '@angular/core';
import { KeycloakConfig } from 'keycloak-js';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {
	endpoints: [string, string];
	keycloakConfig: KeycloakConfig;
	payerSourceOptions: [];
	guidelineSourceOptions: [];
	screenshotPath: string;
	constructor() {}

	public init(data: any) {
		Object.assign(this, data);
	}
}
