import { environment } from './../environments/environment';
import { ErrorModule } from './error/error.module';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './layout/header.component';
import { FooterComponent } from './layout/footer.component';
import { MainComponent } from './layout/main.component';
import { DropdownComponent } from './common/dropdown.component';
import { NavigationBarComponent } from './common/navigationbar.component';

import { AppComponent } from './app.component';
import { InitKeycloak } from './init/keycloak-init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ClientSelectorComponent } from './common/client-selector/client-selector.component';
import { ClientService } from './services/client.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { configurationFactory } from './init/configuration-factory';
import { ConfigurationService } from './init/configuration.service';
import { CapitalAuthGuard } from './init/capital-auth-guard';

const allowedRole = ['PPTOOLSVIEWER']
export const routes: Routes = [
	{ path: '', redirectTo: '/history', pathMatch: 'full' },
	{
		path: 'history',
		canActivate: [CapitalAuthGuard],
		data: { roles : allowedRole },
		loadChildren: () => import('./history/history.module').then((m) => m.HistoryModule)
	},
	{
		path: 'details/:id_type/:id',
		canActivate: [CapitalAuthGuard],
		data: { roles : allowedRole },
		loadChildren: () => import('./details/details.module').then((m) => m.DetailsModule)
	},
	{
		path: 'search',
		canActivate: [CapitalAuthGuard],
		data: { roles : allowedRole },
		loadChildren: () => import('./search/search.module').then((m) => m.SearchModule)
	},
	{
		path: 'compare',
		canActivate: [CapitalAuthGuard],
		data: { roles : allowedRole },
		loadChildren: () => import('./comparison/comparison.module').then((m) => m.ComparisonModule)
	},
	{
		path: 'view',
		canActivate: [CapitalAuthGuard],
		data: { roles : allowedRole },
		loadChildren: () => import('./view/view.module').then((m) => m.ViewModule)
	},
	{
		path: 'error',
		loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule)
	}
];
export const dependents = new InjectionToken<(() => Function)[]>(null);
@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		MainComponent,
		DropdownComponent,
		NavigationBarComponent,
		ClientSelectorComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		KeycloakAngularModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(routes)
	],
	exports: [ ClientSelectorComponent ],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: configurationFactory,
			multi: true,
			deps: [ HttpClient, ConfigurationService, dependents ]
		},
		{
			provide: dependents,
			useFactory: (config: ConfigurationService, keycloak: KeycloakService, clientService: ClientService) => {
				return [ InitKeycloak(config, keycloak) ];
			},
			deps: [ ConfigurationService, KeycloakService, ClientService ]
		}
	],

	bootstrap: [ AppComponent ]
})
export class AppModule {}
