import { ContentDetails, Details } from './crawler-model';
export interface ICrawlerDetail {
  name: string;
  id: string;
  type: string;
  source: string;
  createdAt: Date;
  updatedAt: Date;
  currentVersion: number;
  metadata: ICrawlMetadata[];
}

export interface DetailError {
  code: string;
  description: string;
  stacktrace?: string;
}


export interface ICrawlMetadata {
  policyNumber?: string;
  title?: string;
  content?: string;
  effective?: string;
  nextReview?: string;
  lastReview?: string;
  runId?: string;
  version?: number;
  changeType?: string;s
  changeDate?: Date;
  link?: string;
  hash?: string;
  error: DetailError;
  status: string;
}


export class CrawlerHistoryResponse {
  histories: ICrawlerHistory[] = [];
  total: number = 0;
  currentPage: number = 0;
  limit: number = 0;
}

export class GetDetailsSearchResponseDto {
  details: ICrawlerDetail[];
  total: number;
  currentPage: number;
  limit: number;
}

export class GetDetailsByRunTypeResponse {
  crawlerDetails: { id: string , name: string, currentVersion: string }[];
}

export class GetCrawlerDetilsResponse {
  crawlerDetails: ICrawlerDetail;
}

export class GetContentResponse {
  detailId: string;
  content: string;
  version: number;

  static getContentDetails(id: string, res: GetContentResponse): ContentDetails {
    const response = new ContentDetails();
    response.details =  {id: id, version : res.version };
    response.content = res.content;
    return response;
  }
}



export interface ICrawlerSummary {
  updated: string[];
  deleted: string[];
  new: string[];
  revised: string[];
  inProgress: string[];
}

export class ICrawlerHistory {
  id: string;
  runAt: string;
  runType: string;
  source: string;
  summary: ICrawlerSummary;
  errors: CrawlerError[]
}

export class CrawlerError {
  link: string;
  code: string;
  description: string;
}

export interface CrawlerDetailSearch {
  source: string;

  version: number;

  content: string;

  lastUpdated: string;

  runId: string;

  title: string;

  id: string;
  payer: string;
  link: string;
}

export class GetPolicySearchResponseDto {
  total: number;

  currentPage: number;

  limit: number;

  results: CrawlerDetailSearch[];
}
