export interface Client {
	name: string;
	tenant: string;
}

export class ClientModel implements Client {
	name: string;
	tenant: string;
	constructor(data?: any) {
		this.name = data.name;
		this.tenant = data.tenant;
	}

	get headerValue(): string {
		return `${this.name}-${this.tenant}`.toLocaleLowerCase();
	}
}

export interface ListClient {
	name: string;
	tenants: string[];
}


export interface CrawlHistoryModel {

}

export interface CrawlerDetailModel {

}
