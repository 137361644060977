import { Component, Input } from '@angular/core';

@Component({
	selector: 'dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: [ './dropdown.component.css' ]
})
export class DropdownComponent {
	@Input() dropDownName = '';
	@Input() dropDownValue: Object[] = [];

	constructor() {
		 // console.log(this.dropDownValue);
	}

	onClientChange() {
		// console.log('Client value has changed.');
	}
}
