export class History {
	date: string;
	runId: string;
	crawlType: string;
	source: string;
	updates: number;
	adds: number;
	deletes: number;
	inprogress: number;
	errors: number;
}

export class Details {
	id: string;
	source: string;
	name: string;
	action: string;
	errors: string[];
	url: string;
	version: number;
	payer: string;
}

export class DetailsView extends Details {
	showAllDetails : boolean;
}

export class ContentDetails {
	details: {version: number ,id: string};
	content: string;
}

export interface PayerSearchResults {
	title: string;
	body: string;
	payer: string;
	lastUpdated: string;
	runId: string;
}

export class HistoryFilter {
	runType: string;	
}
