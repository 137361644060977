import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { } from './services/client.service';
import { CrawlerClient } from './services/crawler-client.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading = false;
  subscriptions = [];
  constructor(private crawlerClient: CrawlerClient, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
      const sub = this.crawlerClient.loading$.subscribe({ next : (x) => {
        // console.log('loading', x);
        this.loading = x;
        // this.cdRef.detectChanges();
      },
      error: (x) => console.error(x),
      complete: () =>  { // console.debug('complete') 
     }})

   this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x?.unsubscribe());
  }
}
