import { GetPolicySearchResponseDto, CrawlerHistoryResponse, GetDetailsSearchResponseDto, ICrawlerHistory, GetContentResponse, GetCrawlerDetilsResponse, GetDetailsByRunTypeResponse } from './../model/api-model';
import { Observable, BehaviorSubject, first } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './../init/configuration.service';
import { Injectable } from "@angular/core";
import { ContentDetails, Details, HistoryFilter } from '../model/crawler-model';

@Injectable({
	providedIn: 'root'
})
export class CrawlerClient {
	apiUrl: string;
	searchApiUrl: string;
	searchResponse$ = new BehaviorSubject<GetPolicySearchResponseDto>(new GetPolicySearchResponseDto());
	historyPaginatedResponse$ = new BehaviorSubject<CrawlerHistoryResponse>(new CrawlerHistoryResponse());
	historyResponse$ = new BehaviorSubject<ICrawlerHistory>(new ICrawlerHistory());
	detailSearchResponse$ = new BehaviorSubject<GetDetailsSearchResponseDto>(new GetDetailsSearchResponseDto());
	runTypeDetailsResponse$ = new BehaviorSubject<{runType: string, response: GetDetailsByRunTypeResponse}>({ runType: '', response: new GetDetailsByRunTypeResponse()});
	policyDetailResponse$ = new BehaviorSubject<GetCrawlerDetilsResponse>(new GetCrawlerDetilsResponse());
	contentSearchResponse$ = new BehaviorSubject<ContentDetails>(new ContentDetails());
	error$ = new BehaviorSubject<{ message: string, error: any }>({ message: '', error: {} });
	loading$ = new BehaviorSubject<boolean>(false);
	constructor(conf: ConfigurationService, private httpClient: HttpClient) {
		this.apiUrl = conf.endpoints['crawler-api-url'];
		this.searchApiUrl = conf.endpoints['search-api-url'];
	}

	getPaginatedHistoryv2(filter: any, page: number, limit: number, order:any, changeLoad = true) {
		if(changeLoad) {
			this.loading$.next(true);
		}
		const params: any = {
			page: page,
			limit: limit,
			filter: JSON.stringify(filter),
			order: JSON.stringify(order)
		}

		// console.debug('params');

		this.httpClient.get<CrawlerHistoryResponse>(`${this.apiUrl}/histories/v2`, {
			params: params
		}).subscribe({
			next: (x: CrawlerHistoryResponse) => this.historyPaginatedResponse$.next(x),
			error: (error: any) => {
				console.error(error);
				this.loading$.next(false)
				this.error$.next({ message: 'An error occured while loading history results', error: error });
			},
			complete: () => this.loading$.next(false),
		});
	}

	getDetailsByRunId(runId: string, page: number, limit: number, filter: HistoryFilter) {
		this.loading$.next(true);
		this.httpClient.get<GetDetailsSearchResponseDto>(`${this.apiUrl}/details/run/${runId}`, {
			params: {
				page: page,
				limit: limit
			}
		}).subscribe({
			next: (x: GetDetailsSearchResponseDto) => this.detailSearchResponse$.next(x),
			error: (error: any) => {
				console.error(error);
				this.loading$.next(false)
				this.error$.next({ message: 'An error occured while loading details results', error: error });
			},
			complete: () => this.loading$.next(false),
		});
	}

	getDetailsByRunType(runType: string) {
		if(!runType) return;
		this.loading$.next(true);
		this.httpClient.get<GetDetailsByRunTypeResponse>(`${this.apiUrl}/details/runType/${runType}`).subscribe({
			next: (x) => this.runTypeDetailsResponse$.next({runType, response: x }),
			error: (error: any) => {
				console.error(error);
				this.loading$.next(false)
				this.error$.next({ message: 'An error occured while loading details results', error: error });
			},
			complete: () => this.loading$.next(false),
		});
	}

	getDetailByDetailId(detailId: string) {
		if(!detailId) return;
		this.loading$.next(true);
		this.httpClient.get<GetCrawlerDetilsResponse>(`${this.apiUrl}/details/${encodeURIComponent(detailId)}`)
		.subscribe(({
			next: (x: GetCrawlerDetilsResponse) => this.policyDetailResponse$.next(x),
			error: (err: any) => {
				console.error(err);
				this.loading$.next(false)
				this.error$.next({ message: 'An error occured while getting response', error : err});
			},
			complete: () => this.loading$.next(false),
		}))
	}

	getContent(id: string, version: number) {
		this.loading$.next(true);
		const encodedDetailId = encodeURIComponent(id);
		this.httpClient.get<GetContentResponse>(`${this.apiUrl}/details/${encodedDetailId}/content/${version}`).subscribe({
			next: (x: GetContentResponse) => this.contentSearchResponse$.next(GetContentResponse.getContentDetails(id, x)),
			error: (error: any) => {
				console.error(error);
				this.loading$.next(false)
				this.error$.next({ message: 'An error occured while loading details results', error: error });
			},
			complete: () => this.loading$.next(false),
		});
	}

	searchPolicy(term: string, limit: number, page: number) {
		this.loading$.next(true);
		this.httpClient.get<GetPolicySearchResponseDto>(`${this.searchApiUrl}/search/index`, {
			params: {
				searchTerm: term,
				page: page,
				limit: limit
			}
		})
			.subscribe({
				next: (x: GetPolicySearchResponseDto) => this.searchResponse$.next(x),
				error: (error: any) => {
					this.loading$.next(false)
					console.error(error);
					this.error$.next({ message: 'An error occured while loading search results', error: error });
				},
				complete: () => this.loading$.next(false),
			})
	}

	getHistory(runId: string) {
		this.loading$.next(true);
		this.httpClient.get<ICrawlerHistory>(`${this.apiUrl}/history/${runId}`).subscribe({
			next: (x: ICrawlerHistory) => this.historyResponse$.next(x),
			error: (error: any) => {
				console.error(error);
				this.loading$.next(false)
				this.error$.next({ message: 'An error occured while loading history details', error: error });
			},
			complete: () => this.loading$.next(false),
		})
	}
}