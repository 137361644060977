<div class="row">
    <div class="col-sm-3"></div>
    <div class="col-sm-2">
      <label class="form-label">{{dropDownName}}</label>
    </div>
    <div class="col-sm-7">
      <select name="client" id="client" (click)="onClientChange()">
        <option value="-1">Select a client</option>
        <option value="saab">Saab</option>
        <option value="mercedes">Mercedes</option>
        <option value="audi">Audi</option>
      </select>
    </div>
  </div>