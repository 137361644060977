import { Component } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { ClientService } from '../services/client.service';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: [ './main.component.css' ]
})
export class MainComponent {
	// dropdownLables = [ 'Client', 'Tenant' ];
	// navigationLables = [ 'User Management', 'Directory Management', 'Custom Fields Management', 'Integration History' ];
	// constructor(private clientService: ClientService) {}
	// ngOnInit() {}
	// exportUsers() {
	// 	this.clientService.exportUsers();
	// }
	// exportVendors() {
	// 	this.clientService.exportVendors();
	// }
	// exportProviders() {
	// 	this.clientService.exportProviders();
	// }
	// get isClientSelected(): Observable<boolean> {
	// 	return this.clientService.client$.pipe(map((c) => c.name != 'NA'));
	// }
}
