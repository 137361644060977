import { Component, OnInit } from '@angular/core';
import { first, Observable } from 'rxjs';
import { Client, ClientModel, ListClient } from 'src/app/services/client';
import { ClientService } from 'src/app/services/client.service';

@Component({
	selector: 'app-common-client-selector',
	templateUrl: './client-selector.component.html',
	styleUrls: [ './client-selector.component.css' ]
})
export class ClientSelectorComponent implements OnInit {
	clients: Observable<ClientModel[]>;
	constructor(private service: ClientService) {
		// console.log('init clientSelector');
	}

	ngOnInit(): void {
		this.clients = this.service.listClients();
	}

	get client(): ClientModel {
		return this.service.client;
	}

	set client(client: ClientModel) {
		this.service.client = client;
	}

	compare(client: any, client2: any) {
		return client2 && client && client.name == client2.name && client.tenant == client2.tenant;
	}
}
