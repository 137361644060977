import { Component, Input } from '@angular/core';

@Component({
	selector: 'navigationbar',
	templateUrl: './navigationbar.component.html',
	styleUrls: [ './navigationbar.component.css' ]
})
export class NavigationBarComponent {
	tools = [
		{ label: 'History', route: 'history' },
		{ label: 'Search', route: 'search' }
	]

	menuItems = [
		{
			key: 'history',
			text: 'History'
		},
		{
			key: 'search',
			text: 'search'
		}
	];
}
