import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
	providedIn: 'root'
})
export class HeaderService {
	source$: BehaviorSubject<string> = new BehaviorSubject('');

  changeSource(value: string) {
    this.source$.next(value);
  }
}