import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './configuration.service';

export function InitKeycloak(config: ConfigurationService, keycloak: KeycloakService): () => Promise<any> {
	return (): Promise<any> => {
		console.log('...INITIALIZED KEYCLOAK:');
		return keycloak.init({
			config: config.keycloakConfig,
			initOptions: {
				onLoad: 'login-required',
				checkLoginIframe: false
			},
			enableBearerInterceptor: true
		});
	};
}
