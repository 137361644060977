import { HeaderService } from './../services/header.service';
import { KeycloakService } from 'keycloak-angular';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { Component, OnInit } from "@angular/core";
import { ISymplDropdownOption } from '@symplr-ux/alloy-components/dist/types/model';
import { filter } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {
  /**
   *
   */
  constructor(private router: Router, private kc: KeycloakService, private headerService: HeaderService) {
    this.router.events.pipe(filter(x=> x instanceof NavigationEnd )).subscribe((x: NavigationEnd) => {
       const currentUrl = this.router.url;
       //console.log(currentUrl);
       this.activeItem = this.menuItems.find(x=> currentUrl.includes(x.navigation))?.key || 'history';
    }); 
  }
  
  searchSource: 'PAYER_POLICY'
  searchDropDownOptions: ISymplDropdownOption[] = [
    {
      key: 'ALL',
      text:  "ALL"
    },
    {
      key: 'PAYER_POLICY',
      text:  "Payer Policies"
    },
    {
      key: 'GUIDELINES',
      text:  "Guidelines"
    }
  ]


  activeItem: string = 'history';
  userName: string;
  menuItems: any[] = [
    {
      key: 'history',
      text: 'History',
      navigation: '/history'
    },
    {
      key: 'compare',
      text: 'Compare',
      navigation: '/compare'
    },
    {
      key: 'view',
      text: 'View',
      navigation: '/view'
    },
    {
      key: 'search',
      text: 'Search',
      navigation: '/search'
    }
  ];

  ngOnInit(): void {
    this.kc.loadUserProfile().then(x => {
      this.userName = x.firstName + ' ' + x.lastName;
    })
  
  }

  handleSelection(event: any) {
    const menu = this.menuItems.find(x => x.key === event.detail);

    if (menu) {
      this.router.navigateByUrl(menu.navigation);
    }
  }

  handleProfileSelection(event: any) {
    if (event.detail === 'sign-out') {
      this.kc.logout();
    }
  }
  
  sourceOptionChange(event: any) {
    //console.log(event);
    this.searchSource = event.detail;
    if(this.router.url.includes('/details')) {
      this.router.navigate(['history']).then(x=> {
        this.headerService.changeSource(this.searchSource);
      });
    } else {
      this.headerService.changeSource(this.searchSource);
    }
  }
}