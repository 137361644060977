import { Component } from "@angular/core";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
  })

  export class  FooterComponent {
    limit: number = 5;
    total: number = 10;
    currentPage: number = 1;
    showPaginator: boolean;

    handlePageEvent(event: any) {
      // console.log(event);
      this.currentPage = event.detail.page;
      // this.crawlerClient.getDetailsByRunId(this.runId, this.currentPage, this.limit, null);
    }
  }