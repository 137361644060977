import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigurationService } from './configuration.service';


export function configurationFactory(
	http: HttpClient,
	config: ConfigurationService,
	dependents: (() => Promise<any>)[]
): () => Promise<any> {
	return (): Promise<any> => {
		return new Promise((resolve, reject) => {
			http.get(environment.config).toPromise().then(async (data) => {
				config.init(data);
				// console.log('...INITIALIZED CONFIGURATION');
				for (let i = 0; i < dependents.length; i++) {
					await dependents[i]();
				}
				resolve(true);
			});
		});
	};
}
