import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, first, from, map, Observable, of, tap } from 'rxjs';
import { ClientModel } from './client';
import { ConfigurationService } from '../init/configuration.service';
import { KeycloakService } from 'keycloak-angular';

const LOCAL_STORAGE_KEY = 'CDMI-TOOLS';

@Injectable({
	providedIn: 'root'
})
export class ClientService {
	client$: BehaviorSubject<ClientModel>;
	static _clients_cache: ClientModel[];

	constructor(
		private config: ConfigurationService,
		private httpClient: HttpClient,
		private keycloak: KeycloakService
	) {
		const storage = localStorage.getItem(LOCAL_STORAGE_KEY);
		const initalClient = storage
			? new ClientModel(JSON.parse(storage))
			: new ClientModel({ name: 'mulesoft', tenant: 'training' }); //default client for now

		this.client$ = new BehaviorSubject(initalClient);
	}

	private getClientHeaders(mimeType: string): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': mimeType,
			Client: this.client.headerValue
		});
	}

	get client(): ClientModel {
		return this.client$.getValue();
	}

	set client(client: ClientModel) {
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(client));
		this.client$.next(client);
	}

	listClients(): Observable<ClientModel[]> {
		//temp headers until auth in mulesoft is updated
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Client: 'mulesoft'
		});

		const res = [new ClientModel({ name: "test", tenant: "test" })]
		return from([res]);
		// return ClientService._clients_cache
		// 	? of(ClientService._clients_cache)
		// 	: this.httpClient
		// 			.get(`${this.config.endpoints['mule']}/clients`, {
		// 				headers: headers
		// 			})
		// 			.pipe(
		// 				first(),
		// 				map((resp) =>
		// 					Object.keys(resp).reduce((n, c, a) => {
		// 						resp[c]
		// 							.map((i) => new ClientModel({ name: c, tenant: i.toLocaleLowerCase() }))
		// 							.forEach((c) => n.push(c));
		// 						return n;
		// 					}, [])
		// 				),
		// 				tap((clients) => {
		// 					ClientService._clients_cache = clients;
		// 				})
		// 			);
	}

	exportVendors() {
		return this.httpClient
			.get(`${this.config.endpoints['mule']}/vendors/download`, {
				observe: 'response',
				responseType: 'blob',
				headers: this.getClientHeaders('text/csv')
			})
			.pipe(first())
			.subscribe({
				next: (response: HttpResponse<Blob>) => {
					this.saveFile(this.client.headerValue + '-vendors.csv', response.body);
				},
				error: (error) => {
					alert('error generating the export');
				},
				complete: () => {
					alert('download complete');
				}
			});
	}

	exportProviders() {
		return this.httpClient
			.get(`${this.config.endpoints['mule']}/providers/download`, {
				responseType: 'blob',
				observe: 'response',
				headers: this.getClientHeaders('text/csv')
			})
			.pipe(first())
			.subscribe({
				next: (response: HttpResponse<Blob>) => {
					this.saveFile(this.client.headerValue + '-providers.csv', response.body);
				},
				error: (error) => {
					alert('error generating the export');
				},
				complete: () => {
					alert('download complete');
				}
			});
	}

	exportUsers() {
		this.httpClient
			.get(`${this.config.endpoints['mule']}/users/download`, {
				responseType: 'blob',
				observe: 'response',
				headers: this.getClientHeaders('text/csv')
			})
			.pipe(first())
			.subscribe({
				next: (response: HttpResponse<Blob>) => {
					this.saveFile(this.client.headerValue + '-users.csv', response.body);
				},
				error: (error) => {
					alert('error generating the export');
				},
				complete: () => {
					alert('download complete');
				}
			});
	}

	importUsers(file: File) {
		this.keycloak.getToken().then((token) => {
			const fd = new FormData();
			fd.append('tenantName', this.client.headerValue);
			fd.append('file', file);
			fd.append('access_token', token);
			alert(`if this was live, you would have loaded users from ${file.name} into ${this.client.headerValue}`);

			// this.httpClient.post(`${this.config.endpoints['mule']}/users/upload`, fd).subscribe({
			// 	next: (resp) => {
			// 		console.log('uploaded', resp);
			// 	},
			// 	error: (e) => {
			// 		alert('an error occured uploading the users');
			// 	},
			// 	complete: () => {
			// 		alert('upload complete');
			// 	}
			// });
		});
	}

	private saveFile(filename: string, response: Blob) {
		let dataType = response.type;

		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(new Blob([ response ], { type: dataType }));
		link.download = filename;

		document.body.appendChild(link);
		link.click();
	}
}
